
export function SaveString (str, filename, type) {
  const blob = new Blob([str], { type: type })
  let e = document.createEvent('MouseEvent')
  let a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl = [type, a.download, a.href].join(':')
  e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
  setTimeout(() => {
    window.URL.revokeObjectURL(a.href)
    e = null
    a = null
  }, 100)
}
