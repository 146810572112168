<template>
  <page-header-wrapper>
    <a-card :bordered="false" >
      <p>{{ message }}</p>
      <a-upload
        :file-list="fileList"
        :multiple="false"
        :before-upload="beforeUpload"
      >
        <a-button> <a-icon type="upload" /> 选择玩家数据 </a-button>
      </a-upload>
      <a-button
        type="primary"
        :disabled="fileList.length === 0 || !userData"
        :loading="uploading"
        style="margin-top: 16px"
        @click="handleUpload"
      >
        {{ uploading ? '上传中' : '开始上传' }}
      </a-button>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getPlayerExport, importPlayer } from '@/api/game/mai2'
import { SaveString } from '@/utils/download'

export default {
  name: 'PlayerImport',
  data () {
    return {
      message: '',
      fileList: [],
      uploading: false,
      userData: null
    }
  },
  mounted () {
  },
  methods: {
    importPlayer () {
      getPlayerExport({ playerId: this.id }).then(res => {
        SaveString(JSON.stringify(res), `mai2user-${this.id}.json`, 'application/json')
      })
    },
    beforeUpload (file) {
      this.fileList = [file]
      const fr = new FileReader()
      fr.onload = () => {
        try {
          this.userData = JSON.parse(fr.result)
          this.message = '你确定要导入以下用户：' + this.userData.userData.userName
        } catch (e) {
          this.userData = null
          this.message = '读取错误，请确认是否选择了正确的文件'
        }
      }
      fr.readAsText(file)
      return false
    },
    handleUpload () {
      importPlayer(this.userData).then(res => {
        this.fileList = []
        this.userData = null
      })
    }
  }
}
</script>

<style scoped>

</style>
